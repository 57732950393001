<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <template>
        <b-card>
          <!-- form -->
          <b-form class="mt-2">
            <b-row>
              <b-col sm="12">
                <b-form-group label="Nama Lengkap">
                  <b-form-input
                    v-model="localData.name"
                    placeholder="Nama Lengkap"
                    name="namalengkap"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="E-mail" label-for="user-email">
                  <b-form-input
                    v-model="localData.email"
                    name="email"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Username" label-for="user-username">
                  <b-form-input
                    v-model="localData.username"
                    name="username"
                    placeholder="Username"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label-for="account-password"
                  label="Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-password"
                      v-model="localData.password"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      placeholder="Password"
                      autocomplete
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Pilih Roles"
                >
                  <v-select
                    v-model="localData.role_id"
                    label="name"
                    :options="localOptions"
                    @input="pilihRole"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="isPilihDPC">
                <b-form-group
                  label="Pilih DPC"
                >
                  <b-form-select
                    v-model="localData.dpn_dpc_id"
                    :options="optionsStrukturOrganisasi"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="simpan"
                >
                  <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
                  Simpan
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </template>
    </b-tab>
    <!--/ general tab -->

  </b-tabs>
</template>

<script>
import {
  BButton,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BTabs,
  BTab,
  BFormSelect,
} from 'bootstrap-vue'
import { getCacheableApi, api } from '@/hap-sia/setup'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BTabs,
    BTab,
    vSelect,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localData: {
        name: null,
        email: null,
        username: null,
        password: null,
        role_id: null,
        dpn_dpc_id: null,
      },
      btnSimpanSpinnerShow: false,
      passwordFieldTypeNew: 'password',
      masterUser: null,
      localOptions: [
        { id: 'verifikator', name: 'Verifikator' },
        { id: 'operator', name: 'Operator' },
        { id: 'admin', name: 'Administrator' },
        { id: 'member', name: 'Member' },
        { id: 'public', name: 'Publik' },
        { id: 'user', name: 'User' },
        { id: 'percetakan', name: 'Percetakan' },
      ],
      isPilihDPC: false,
      masterStrukturOrganisasi: null,
      optionsStrukturOrganisasi: [{ value: null, text: '--Pilih DPN/DPC--' }],
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    const cacheApi = await getCacheableApi()
    try {
      const resp = await cacheApi.get('master/struktur-organisasi')
      this.masterStrukturOrganisasi = resp.data
      for (let i = 0; i < this.masterStrukturOrganisasi.length; i += 1) {
        this.optionsStrukturOrganisasi.push({
          value: this.masterStrukturOrganisasi[i].id,
          text: this.masterStrukturOrganisasi[i].nama,
        })
      }
    } catch (error) {
      alert(error.response.data.message)
      this.loadingMessage = 'Something went wrong. Please refresh browser'
    }
  },
  methods: {
    pilihRole(event) {
      if (event.id === 'operator') {
        this.isPilihDPC = true
      } else {
        this.isPilihDPC = false
      }
    },
    async simpan() {
      if (!this.localData.name || !this.localData.email || !this.localData.username || !this.localData.password || !this.localData.role_id) {
        this.makeToast('danger', 'Error', 'Semua Kolom Wajib Diisi', true)
        return
      }
      this.localData.role_id = this.localData.role_id.id

      this.btnSimpanSpinnerShow = true
      try {
        const result = await api().post('user', this.localData)
        if (result) {
          this.$router.push('/users')
        }
      } catch (error) {
        this.makeToast('danger', 'Error', error.response.data.message, true)
        return
      }
      this.makeToast('success', 'Sukses', 'Data berhasil ditambahkan')
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
